import { UserStorage } from "~/Storages/userStorage";
import { routes } from "~/enum/route.enum";

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.client) {
    const hasToken = UserStorage.getUserToken();
    if (!hasToken) return navigateTo(routes.LOGIN)
    else return 
  }
});
